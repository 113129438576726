import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  font-size: ${({ theme }) => theme.font.size.md};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
}

html,
body {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.color.bg};
}

input {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.blue.primary};
    opacity: 1; /* Firefox */
    font-family: 'thin';
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.blue.primary};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.blue.primary};
  }
}

.tooltip {
  --tooltip-offset: calc(100% + var(--tooltip-tail, 0px))
}
`

export default GlobalStyles
