import { useSelector } from 'react-redux'
import Container from '../../Container/Container'
import facebook from '../../../../assets/image/socialmedia/facebook.png'
import instagram from '../../../../assets/image/socialmedia/instagram.png'
import linkedin from '../../../../assets/image/socialmedia/linkedin.png'
import props from '../../../../redux/props'

const Footer = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const icons = {
    facebook: facebook,
    instagram: instagram,
    linkedin: linkedin,
  }

  return (
    <div className="bg-white pt-16">
      <div className="border-t border-blue py-5">
        <Container>
          <div className="flex flex-col md:flex-row gap-5 md:gap-20">
            <div className="md:w-2/6 shrink-0 text-lg font-bold text-blue">{t.footer.contact}</div>
            <div className="md:w-4/6 text-lg flex flex-col md:flex-row gap-0 md:gap-10 md:items-center">
              <a href={`tel:${t.generic.company.mail}`} className="text-blue font-thin">
                {t.generic.company.phone}
              </a>
              <a href={`mailto:${t.generic.company.mail}`} className="text-blue font-thin">
                {t.generic.company.mail}
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className="border-t border-blue py-5">
        <Container>
          <div className="flex flex-col md:flex-row gap-5 md:gap-20">
            <div className="md:w-2/6 shrink-0 text-lg font-bold text-blue">{t.footer.links}</div>
            <div className="md:w-4/6 text-lg flex flex-col md:flex-row gap-0 md:gap-10 md:items-center">
              {t.footer.legalLinks.map((link: { name: string; link: string }, i: number) => (
                <a href={link.link} className="text-lg font-thin text-blue" key={i}>
                  {link.name}
                </a>
              ))}
            </div>
          </div>
        </Container>
      </div>
      <div className="border-t border-blue py-5">
        <Container>
          <div className="flex flex-col md:flex-row gap-5 md:gap-20">
            <div className="w-2/6 shrink-0 text-lg font-bold text-blue">{t.footer.followUs}</div>
            <div className="w-4/6 text-lg flex flex-row gap-5 md:gap-10 md:items-center">
              {t.footer.socialLinks.map((link: { icon: string; link: string }, i: number) => (
                <a href={link.link} className="text-lg font-thin text-blue" key={i} target="_blank" rel="noreferrer">
                  <img src={icons[link.icon]} className="h-5" alt={link.icon} />
                </a>
              ))}
            </div>
          </div>
        </Container>
      </div>
      <div className="border-t border-blue py-5">
        <Container>
          <div className="text-lg font-thin text-blue">
            &copy;{` ${new Date().getFullYear()} `}
            {t.generic.company.name}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Footer
