import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import store from './redux/configureStore'
import './global.scss'

// Find the root element in the DOM
const container = document.getElementById('root')

// Create a root
const root = createRoot(container)

// Initial render
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)
