import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import VideoBackground from './VideoBackground'

const Home = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout hideNavigation noHeader>
      <VideoBackground>
        <div className="flex h-full items-end justify-center">
          <div className="flex flex-col items-center gap-10 mb-10">
            <div className="w-[90%] md:w-2/3 mx-auto text-xs md:text-md font-light text-lightGray text-center">{t.home.first}</div>
            <div>
              <div className="text-xs md:text-md text-lightGray font-light text-center">{t.home.second}</div>
              <div className="text-xs md:text-md text-white font-bold text-center">{t.home.highlight}</div>
            </div>
          </div>
        </div>
      </VideoBackground>
    </Layout>
  )
}

export default Home
