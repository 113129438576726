const VideoBackground = ({ children }) => {
  return (
    <div className="w-full h-full">
      <div className="h-screen w-screen relative z-0 left-0 top-0">
        <video
          className="hidden md:block w-screen h-screen"
          src="https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.1stclassjets.homepage/STATIC_MANUAL_ASSETS/intro_videos/desktop.mp4"
          autoPlay
          loop
          muted
          playsInline
          poster="https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.1stclassjets.homepage/STATIC_MANUAL_ASSETS/intro_videos/desktop.webp"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transform: 'translate(-50%, -50%)',
          }}
        >
          Your browser does not support the video tag.
        </video>
        <video
          className="md:hidden"
          src="https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.1stclassjets.homepage/STATIC_MANUAL_ASSETS/intro_videos/mobile.mp4"
          autoPlay
          loop
          muted
          playsInline
          poster="https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.1stclassjets.homepage/STATIC_MANUAL_ASSETS/intro_videos/mobile.webp"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transform: 'translate(-50%, -50%)',
          }}
        >
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="absolute w-full h-full left-0 top-0">{children}</div>
    </div>
  )
}

export default VideoBackground
