import { translation, langKey } from '../translations/de'

export type DefaultState = {
  TRANSLATION: { [key: string]: any }
  CURRENTLANG: String
}

const defaultState: DefaultState = {
  TRANSLATION: translation,
  CURRENTLANG: langKey,
}

export default defaultState
