import Toggle from './Toggel/Toggle'
import Items from './Items/Items'

const Navigation: React.FC<{}> = () => {
  return (
    <div>
      <div className="block lg:hidden">
        <Toggle />
      </div>
      <div className="hidden lg:flex justify-around gap-8 items-center">
        <Items />
      </div>
    </div>
  )
}

export default Navigation
