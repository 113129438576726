import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import './Global.Styled'
import Home from './components/Home/Home'
import Imprint from './components/Legal/Imprint'
import DataProtection from './components/Legal/DataProtection'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {/* Home */}
            <Route index element={<Home />} />

            {/* Legal */}
            <Route path="impressum" element={<Imprint />} />
            <Route path="datenschutz" element={<DataProtection />} />

            {/* fallback routing */}
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App
