import Header from './Header/Header'
import Footer from './Footer/Footer'
import ScrollToTop from './ScrollToTop'

type Props = {
  children: React.ReactNode
  className?: string
  hideNavigation?: boolean
  noHeader?: boolean
  noFooter?: boolean
  disableDynamicHeightHeader?: boolean
  smallHeader?: boolean
}

const Layout: React.FC<Props> = ({
  children,
  className,
  hideNavigation,
  noHeader = false,
  noFooter = false,
  disableDynamicHeightHeader = false,
  smallHeader = false,
}) => {
  return (
    <div className={`bg-background ${className}`}>
      <ScrollToTop />
      {!noHeader && <Header hideNavigation={hideNavigation} disableDynamicHeight={disableDynamicHeightHeader} small={smallHeader} />}
      <div className={`relative overflow-x-hidden min-h-screen ${smallHeader ? 'pt-12' : noHeader ? 'pt-0' : 'pt-16'}`}>{children}</div>
      {!noFooter && <Footer />}
    </div>
  )
}

export default Layout
