import { useEffect, useState } from 'react'

const useDidScroll = () => {
  const [didScroll, setDidScroll] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setDidScroll(true)
      } else {
        setDidScroll(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return didScroll
}

export default useDidScroll
